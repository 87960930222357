module services {
    export module costing {

        export class costParameterDestinationCountryService implements interfaces.costing.ICostParameterDestinationCountryService {

            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDestinationCountryList(): ng.resource.IResourceClass<interfaces.costing.ICostDestinationCountry> {
                return this.$resource<interfaces.costing.ICostDestinationCountry>(this.ENV.DSP_URL + "CostParameterDestinationCountry/GetDestinationCountryList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    countryId: '@countryId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            saveDestinationCountry(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterDestinationCountry/SaveDestinationCountry", {
                });
            }

            removeDestinationCountry(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterDestinationCountry/RemoveDestinationCountry", {
                    cpuId: '@cpuId',
                });
            }


        }

    }

    angular.module("app").service("costParameterDestinationCountryService", services.costing.costParameterDestinationCountryService);
}